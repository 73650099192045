import React from 'react'
import { Link } from 'react-router-dom'
import './card.scss' // Import the CSS file for styling
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'

const CardComponent = ({ name, imageUrl, url }) => {
    return (
        <div className="card">
            <div className="icons">
                <Link to={`${window.location.origin}/${url}`}>
                    <AddCircleIcon
                        style={{
                            fontSize: 25,
                            marginTop: '5px',
                            color: '#7056B5',
                        }}
                    />
                </Link>
                <span className="icon listing-icon">
                    <Link to={`${window.location.origin}/${url}`}>
                        <ListAltOutlinedIcon
                            style={{
                                fontSize: 25,
                                marginTop: '5px',
                                color: '#7056B5',
                            }}
                        />
                    </Link>
                </span>
            </div>
            <img
                src={imageUrl}
                alt={name}
                className="card-image"
                width={50}
                height={50}
            />
            <hr className="line" />
            <div className="footer">
                <p>{name}</p>
            </div>
        </div>
    )
}

export default CardComponent
