import { useContext, useEffect } from 'react'
import './App.scss'
import { ThemeContext } from './context/ThemeContext'
import { DARK_THEME, LIGHT_THEME } from './constants/themeConstants'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import MoonIcon from './assets/icons/moon.svg'
import SunIcon from './assets/icons/sun.svg'
import BaseLayout from './layout/BaseLayout'
import {
    Dashboard,
    PageNotFound,
    Order,
    ProductCategory,
    Restaurant,
    Product,
    Supplier,
    Login,
    Payment,
    Shipping,
    Users,
    Reviews,
    Register,
} from './screens'

function App() {
    const { theme, toggleTheme } = useContext(ThemeContext)

    // adding dark-mode class if the dark mode is set on to the body tag
    useEffect(() => {
        if (theme === DARK_THEME) {
            document.body.classList.add('dark-mode')
        } else {
            document.body.classList.remove('dark-mode')
        }
    }, [theme])

    useEffect(() => {
        localStorage.removeItem('searchParams')
    }, [window.location.pathname])

    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route element={<BaseLayout />}>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/restaurant" element={<Restaurant />} />
                        <Route
                            path="/product-category"
                            element={<ProductCategory />}
                        />
                        <Route path="/product" element={<Product />} />
                        <Route path="/order-list" element={<Order />} />
                        <Route path="/supplier" element={<Supplier />} />
                        <Route path="/payment" element={<Payment />} />
                        <Route path="/shipping" element={<Shipping />} />
                        <Route path="/reviews" element={<Reviews />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Route>
                </Routes>

                <button
                    type="button"
                    className="theme-toggle-btn"
                    onClick={toggleTheme}
                >
                    <img
                        className="theme-icon"
                        src={theme === LIGHT_THEME ? SunIcon : MoonIcon}
                    />
                </button>
            </Router>
        </>
    )
}

export default App
