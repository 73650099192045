import { useContext, useEffect, useRef } from 'react'
import { ThemeContext } from '../../context/ThemeContext'
import { LIGHT_THEME } from '../../constants/themeConstants'
import cocoaLogo from '../../assets/icons/cocoaLogo.png'
import {
    MdOutlineClose,
    MdOutlineGridView,
    MdOutlineLogout,
} from 'react-icons/md'
import Restaurant from '../../assets/icons/Restaurant.png'
import Category from '../../assets/icons/Categories.png'
import Products from '../../assets/icons/Products.png'
import Supplier from '../../assets/icons/Supplier.png'
import Order from '../../assets/icons/Order.png'
import Payment from '../../assets/icons/Payment.png'
import Shipping from '../../assets/icons/Shipping.png'
import Reviews from '../../assets/icons/Reviews.png'
import Users from '../../assets/icons/Users.png'
import { Link, useNavigate } from 'react-router-dom'
import './Sidebar.scss'
import { SidebarContext } from '../../context/SidebarContext'

const Sidebar = () => {
    const history = useNavigate()
    const { theme } = useContext(ThemeContext)
    const { isSidebarOpen, closeSidebar } = useContext(SidebarContext)
    const navbarRef = useRef(null)

    useEffect(() => {
        localStorage.removeItem('searchParams')
    }, [window.location.pathname])

    // closing the navbar when clicked outside the sidebar area
    const handleClickOutside = (event) => {
        if (
            navbarRef.current &&
            !navbarRef.current.contains(event.target) &&
            event.target.className !== 'sidebar-oepn-btn'
        ) {
            closeSidebar()
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <nav
            className={`sidebar ${isSidebarOpen ? 'sidebar-show' : ''}`}
            ref={navbarRef}
        >
            <div className="sidebar-top">
                <div className="sidebar-brand">
                    <img
                        src={theme === LIGHT_THEME ? cocoaLogo : cocoaLogo}
                        alt=""
                    />
                </div>
                <button className="sidebar-close-btn" onClick={closeSidebar}>
                    <MdOutlineClose size={24} />
                </button>
            </div>
            <div className="sidebar-body">
                <div className="sidebar-menu">
                    <ul className="menu-list">
                        <li className="menu-item">
                            <Link
                                to="/dashboard"
                                className={`menu-link ${
                                    window.location.pathname === '/dashboard'
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <span className="menu-link-icon">
                                    <MdOutlineGridView size={20} />
                                </span>
                                <span className="menu-link-text">
                                    Dashboard
                                </span>
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link
                                to="/restaurant"
                                className={`menu-link ${
                                    window.location.pathname === '/restaurant'
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <span className="menu-link-icon">
                                    <img
                                        src={Restaurant}
                                        width={20}
                                        height={20}
                                    />
                                </span>
                                <span className="menu-link-text">
                                    Restaurant
                                </span>
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link
                                to="/product-category"
                                className={`menu-link ${
                                    window.location.pathname ===
                                    '/product-category'
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <span className="menu-link-icon">
                                    <img
                                        src={Category}
                                        width={20}
                                        height={20}
                                    />
                                </span>
                                <span className="menu-link-text">Category</span>
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link
                                to="/product"
                                className={`menu-link ${
                                    window.location.pathname === '/product'
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <span className="menu-link-icon">
                                    <img
                                        src={Products}
                                        width={20}
                                        height={20}
                                    />
                                </span>
                                <span className="menu-link-text">Product</span>
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link
                                to="/supplier"
                                className={`menu-link ${
                                    window.location.pathname === '/supplier'
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <span className="menu-link-icon">
                                    <img
                                        src={Supplier}
                                        width={20}
                                        height={20}
                                    />
                                </span>
                                <span className="menu-link-text">Supplier</span>
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link
                                to="/order-list"
                                className={`menu-link ${
                                    window.location.pathname === '/order-list'
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <span className="menu-link-icon">
                                    <img src={Order} width={20} height={20} />
                                </span>
                                <span className="menu-link-text">Order</span>
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link
                                to="/payment"
                                className={`menu-link ${
                                    window.location.pathname === '/payment'
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <span className="menu-link-icon">
                                    <img src={Payment} width={20} height={20} />
                                </span>
                                <span className="menu-link-text">Payment</span>
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link
                                to="/shipping"
                                className={`menu-link ${
                                    window.location.pathname === '/shipping'
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <span className="menu-link-icon">
                                    <img
                                        src={Shipping}
                                        width={20}
                                        height={20}
                                    />
                                </span>
                                <span className="menu-link-text">Shipping</span>
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link
                                to="/reviews"
                                className={`menu-link ${
                                    window.location.pathname === '/reviews'
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <span className="menu-link-icon">
                                    <img src={Reviews} width={20} height={20} />
                                </span>
                                <span className="menu-link-text">Reviews</span>
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link
                                to="/users"
                                className={`menu-link ${
                                    window.location.pathname === '/users'
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <span className="menu-link-icon">
                                    <img src={Users} width={20} height={20} />
                                </span>
                                <span className="menu-link-text">Users</span>
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="sidebar-menu sidebar-menu2">
                    <ul className="menu-list">
                        <li className="menu-item">
                            <Link to="/" className="menu-link">
                                <span className="menu-link-icon">
                                    <MdOutlineLogout size={20} />
                                </span>
                                <span className="menu-link-text">Logout</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Sidebar
