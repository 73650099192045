import * as React from 'react'
import { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import utc from 'dayjs/plugin/utc'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import FormControl from '@mui/material/FormControl'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import CardActions from '@mui/material/CardActions'
import IconButton from '@mui/material/IconButton'
import ImageUploading from 'react-images-uploading'
import CloseIcon from '@mui/icons-material/Close'
import FormControlLabel from '@mui/material/FormControlLabel'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import { InputLabel } from '@mui/material'
import './Restaurant.scss'
import { BACKEND_URL, restaurantTableHeads } from '../../constant.js'
import { CustomAlert, CustomTab } from '../../components'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import LoadingButton from '@mui/lab/LoadingButton'

const RestaurantForm = () => {
    dayjs.extend(utc)

    const location = useLocation()
    const [updateBtnText, setUpdateBtnText] = useState(false)
    const [submittedRestaurant, setSubmittedRestaurant] = useState(false)
    const [fieldsDisabled, setFieldsDisabled] = useState(false)
    const [images, setImages] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [authLoading, setAuthLoading] = React.useState(false)
    const [alert, setAlert] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState()
    const [severity, setSeverity] = useState()

    const [formData, setFormData] = useState({
        restaurantID: 'Auto Generated',
        userId: '',
        restaurantName: '',
        companyName: '',
        coRegistrationNumber: '',
        address: '',
        email: '',
        phone: '',
        companyRegDate: dayjs().format('YYYY-DD-MM'),
        city: '',
        state: '',
        zipCode: '',
        country: '',
        status: '',
        statusDate: '',
        focalPerson: '',
        focalPersonHp: '',
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const [formAuthData, setFormAuthData] = useState({
        status: 'active',
        statusDate: dayjs().format('YYYY-MM-DD'),
        focalPerson: '',
        focalPersonHandphone: '',
    })
    const maxNumber = 69

    const onChange = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex)
        setImages(imageList)
    }

    React.useEffect(() => {
        let timer
        if (alert) {
            timer = setTimeout(() => {
                setAlert(false)
            }, 10000)
        }
        return () => clearTimeout(timer)
    }, [alert])

    const getParamsFromLocalStorage = () => {
        const searchParamsString = localStorage.getItem('searchParams')
        if (searchParamsString) {
            return new URLSearchParams(searchParamsString)
        }
        return new URLSearchParams()
    }

    useEffect(() => {
        const params = getParamsFromLocalStorage()
        setFormData({
            restaurantID: params.get('RestaurantID') || 'Auto Generated',
            userId: params.get('UserID') || '',
            restaurantName: params.get('RestaurantName') || '',
            companyName: params.get('CompanyName') || '',
            coRegistrationNumber: params.get('CompanyRegNo') || '',
            address: params.get('Address') || '',
            email: params.get('Email') || '',
            phone: params.get('Phone') || '',
            companyRegDate: params.get('CompanyRegDate') || '',
            city: params.get('City') || '',
            state: params.get('State') || '',
            zipCode: params.get('ZipCode') || '',
            country: params.get('Country') || '',
            status: params.get('Status') || '',
            statusDate: params.get('StatusDate') || '',
            focalPerson: params.get('FocalPerson') || '',
            focalPersonHp: params.get('FocalPersonHp') || '',
        })
        setFormAuthData({
            registrationDate: params.get('RegistrationDate') || '',
            status: 'active',
            statusDate: params.get('statusDate') || '',
            focalPerson: params.get('FocalPerson') || '',
            focalPersonHandphone: params.get('FocalPersonHp') || '',
        })
    }, [localStorage.getItem('searchParams')])

    const clearForm = () => {
        setImages([])
        setUpdateBtnText(false)
        setSubmittedRestaurant(false)
        setFieldsDisabled(false)
        document.getElementsByClassName('basic-restaurant-form')[0].reset()
        setFormAuthData({
            status: 'active',
            statusDate: '',
            focalPerson: '',
            focalPersonHandphone: '',
        })
        setAuthLoading(false)

        setFormData({
            restaurantID: 'Auto Generated',
            userId: '',
            restaurantName: '',
            coRegistrationNumber: '',
            address: '',
            email: '',
            phone: '',
            companyRegDate: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
            status: '',
            statusDate: '',
            focalPerson: '',
            focalPersonHp: '',
        })

        if (localStorage.getItem('searchParams')) {
            // Remove the searchParams item from localStorage
            localStorage.removeItem('searchParams')

            const newUrl = window.location.pathname
            window.history.pushState({ path: newUrl }, '', newUrl)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        // Create an object with form data

        const restaurantData = {
            ...formData,
            relatedDocs:
                images.length > 0 ? images.map((image) => image.data_url) : '',
        }

        // Log the object to the console
        // setRestaurantFilledData(restaurantData)

        if (document.querySelector('.submit-btn').firstChild.data === 'Edit') {
            //Edit Button
            setUpdateBtnText(true)
            setFieldsDisabled(false)
            // setSubmittedRestaurant(false)
        } else if (
            document.querySelector('.submit-btn').firstChild.data === 'Update'
        ) {
            setFieldsDisabled(true)
            setUpdateBtnText(false)

            try {
                // Make PUT request
                setLoading(true)

                const response = await axios.put(
                    `${BACKEND_URL}/updateRestaurant/${formData.restaurantID}`,
                    restaurantData
                )

                console.log(response.data) // Handle response
                setFieldsDisabled(true)
                setSubmittedRestaurant(true)
                setLoading(false)

                setAlert(true)
                setAlertMessage('Successfully Updated Restaurant!')
                setSeverity('success')
            } catch (error) {
                setAlert(true)
                setLoading(false)
                setAlertMessage('Failed To Update Restaurant!')
                setSeverity('error')

                console.error('Error:', error)
            }
            // setSubmittedRestaurant(true)
        } else {
            console.log('Submit button')
            try {
                setLoading(true)
                // Make POST request
                const response = await axios.post(
                    `${BACKEND_URL}/addRestaurant`,
                    restaurantData
                )

                formData['restaurantID'] =
                    response && response.data.restaurantID

                console.log(response.data) // Handle response
                setFieldsDisabled(true)
                setSubmittedRestaurant(true)
                setLoading(false)
                setAlert(true)

                setSeverity('success')
                setAlertMessage('Successfully Added Restaurant!')
            } catch (error) {
                setAlert(true)
                setLoading(false)
                setAlertMessage(
                    'Failed To Add Restaurant!  Duplicate UserId, Supplier Name or Email'
                )
                setSeverity('error')

                console.error('Error:', error)
            }
        }

        // Reset the form
        // event.target.reset()
        // setImages([])
    }

    const handleInputChange = async (event) => {
        const { name, value } = event.target
        setFormAuthData({
            ...formAuthData,
            [name]: value,
        })
    }

    const handleAuthorizationSubmit = async (event) => {
        event.preventDefault()
        try {
            formAuthData['statusDate'] = dayjs().format('YYYY-MM-DD')
            setAuthLoading(true)

            // Make a request to update authorization
            const response = await axios.put(
                `${BACKEND_URL}/updateRestaurantAuthorization/${formData.restaurantID}`,
                formAuthData
            )
            console.log(response.data) // Log response data
            setAlert(true)
            setAlertMessage('Successfully Updated Restaurant Authorization!')
            setSeverity('success')
            setAuthLoading(false)

            // Optionally, update state or perform other actions after successful submission
        } catch (error) {
            setAlert(true)
            setAuthLoading(false)
            setAlertMessage('Failed To Update Restaurant Authorization!')
            setSeverity('error')
            console.error('Error updating authorization:', error)
            // Handle error, e.g., display error message to user
        }
    }

    useEffect(() => {
        if (window.location.search !== '') {
            setUpdateBtnText(true)
            setSubmittedRestaurant(true)
        }
    }, [localStorage.getItem('searchParams')])

    console.log('Company Reg Date : ', formData.companyRegDate)

    return (
        <>
            {alert && (
                <CustomAlert
                    message={alertMessage}
                    severity={severity}
                    setAlert={setAlert}
                    style={{ zIndex: 99999 }}
                />
            )}
            <Box sx={{ padding: 5 }}>
                <h4 style={{ marginBottom: '15px' }}>Basic Information</h4>
                <form onSubmit={handleSubmit} className="basic-restaurant-form">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="filled-disabled"
                                name="restaurantId"
                                label="Restaurant ID"
                                fullWidth
                                placeholder="Auto Generated"
                                size="medium"
                                variant="filled"
                                autoComplete="on"
                                value={formData.restaurantID}
                                onChange={handleChange}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="userId"
                                name="userId"
                                label="User ID"
                                fullWidth
                                size="medium"
                                autoComplete="on"
                                variant="outlined"
                                value={formData.userId}
                                onChange={handleChange}
                                disabled={fieldsDisabled}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth size="medium">
                                <TextField
                                    required
                                    id="restaurantName"
                                    name="restaurantName"
                                    label="Restaurant Name"
                                    fullWidth
                                    size="medium"
                                    autoComplete="on"
                                    variant="outlined"
                                    value={formData.restaurantName}
                                    onChange={handleChange}
                                    disabled={fieldsDisabled}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth size="medium">
                                <TextField
                                    required
                                    id="companyName"
                                    name="companyName"
                                    label="Company Name"
                                    fullWidth
                                    size="medium"
                                    autoComplete="on"
                                    variant="outlined"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                    disabled={fieldsDisabled}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="coRegistrationNumber"
                                name="coRegistrationNumber"
                                label="Co. Registration No"
                                fullWidth
                                size="medium"
                                autoComplete="on"
                                variant="outlined"
                                value={formData.coRegistrationNumber}
                                onChange={handleChange}
                                disabled={fieldsDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                id="address"
                                name="address"
                                label="Address"
                                fullWidth
                                size="medium"
                                autoComplete="on"
                                variant="outlined"
                                value={formData.address}
                                onChange={handleChange}
                                disabled={fieldsDisabled}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="email"
                                name="email"
                                label="Email"
                                fullWidth
                                size="medium"
                                autoComplete="on"
                                variant="outlined"
                                value={formData.email}
                                onChange={handleChange}
                                disabled={fieldsDisabled}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="phone"
                                name="phone"
                                label="Phone"
                                fullWidth
                                size="medium"
                                autoComplete="on"
                                variant="outlined"
                                value={formData.phone}
                                onChange={handleChange}
                                disabled={fieldsDisabled}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        size="medium"
                                        label="Company Registration Date"
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                companyRegDate:
                                                    e.format('YYYY-MM-DD'),
                                            })
                                        }}
                                        disableFuture
                                        format="YYYY-MM-DD"
                                        value={
                                            formData.companyRegDate
                                                ? dayjs(formData.companyRegDate)
                                                : null
                                        }
                                        disabled={fieldsDisabled}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                fullWidth
                                                size="medium"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="city"
                                name="city"
                                label="City"
                                fullWidth
                                size="medium"
                                autoComplete="on"
                                variant="outlined"
                                value={formData.city}
                                onChange={handleChange}
                                disabled={fieldsDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="state"
                                name="state"
                                label="State"
                                fullWidth
                                size="medium"
                                value={formData.state}
                                onChange={handleChange}
                                autoComplete="on"
                                variant="outlined"
                                disabled={fieldsDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="zipCode"
                                name="zipCode"
                                label="Zip Code"
                                fullWidth
                                type="number"
                                size="medium"
                                value={formData.zipCode}
                                onChange={handleChange}
                                autoComplete="on"
                                variant="outlined"
                                disabled={fieldsDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="country"
                                name="country"
                                label="Country"
                                fullWidth
                                size="medium"
                                value={formData.country}
                                onChange={handleChange}
                                autoComplete="on"
                                variant="outlined"
                                disabled={fieldsDisabled}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <h4
                                style={{
                                    marginBottom: '15px',
                                    marginTop: '15px',
                                }}
                            >
                                Upload Related Documents
                            </h4>
                            <ImageUploading
                                multiple
                                allowNonImageType={true}
                                value={images}
                                onChange={onChange}
                                accept=".pdf,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx"
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemove,
                                }) => (
                                    <div
                                        className="upload__image-wrapper"
                                        style={{ display: 'flex' }}
                                    >
                                        <Card
                                            style={{
                                                marginRight: '10px',
                                                width: '40px',
                                                height: '40px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: fieldsDisabled
                                                    ? 'grey'
                                                    : '#7056b5',
                                            }}
                                        >
                                            <CardActions>
                                                <IconButton
                                                    onClick={onImageUpload}
                                                    disabled={fieldsDisabled}
                                                >
                                                    <AddIcon
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    />
                                                </IconButton>
                                            </CardActions>
                                        </Card>
                                        {imageList.map((image, index) => (
                                            <Card
                                                key={index}
                                                style={{
                                                    position: 'relative',
                                                    marginRight: '10px',
                                                }}
                                            >
                                                <CardHeader
                                                    avatar={
                                                        <img
                                                            src={
                                                                image[
                                                                    'data_url'
                                                                ]
                                                            }
                                                            alt=""
                                                            width="40"
                                                            height="40"
                                                        />
                                                    }
                                                />
                                                <CardActions
                                                    style={{
                                                        position: 'absolute',
                                                        top: '0',
                                                        right: '0',
                                                    }}
                                                >
                                                    <IconButton
                                                        onClick={() =>
                                                            onImageRemove(index)
                                                        }
                                                        disabled={
                                                            fieldsDisabled
                                                        }
                                                    >
                                                        <CloseIcon
                                                            style={{
                                                                cursor: 'pointer',
                                                                backgroundColor:
                                                                    'white',
                                                                borderRadius:
                                                                    '50%',
                                                            }}
                                                        />
                                                    </IconButton>
                                                </CardActions>
                                            </Card>
                                        ))}
                                    </div>
                                )}
                            </ImageUploading>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            {formData.restaurantID !== 'Auto Generated' && (
                                <Grid item sx={{ mr: 1 }}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className="reset-btn"
                                        onClick={clearForm}
                                    >
                                        Add New Restaurant
                                    </Button>
                                </Grid>
                            )}
                            <Grid item sx={{ mr: 1 }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className="reset-btn"
                                    onClick={clearForm}
                                >
                                    Reset
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="submit-btn"
                                    disabled={loading}
                                    style={{ position: 'relative' }}
                                >
                                    {loading ? (
                                        <CircularProgress
                                            size={24}
                                            color="inherit"
                                        />
                                    ) : submittedRestaurant ? (
                                        updateBtnText ? (
                                            'Update'
                                        ) : (
                                            'Edit'
                                        )
                                    ) : (
                                        'Submit'
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>

                {formData.restaurantID !== 'Auto Generated' && (
                    <>
                        <h4
                            style={{
                                marginBottom: '15px',
                                marginTop: '15px',
                            }}
                        >
                            Authorization
                        </h4>
                        <form
                            onSubmit={handleAuthorizationSubmit}
                            className="authorization-form"
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DemoContainer
                                            components={['DatePicker']}
                                        >
                                            <DatePicker
                                                size="medium"
                                                label="Registration Date"
                                                onChange={(e) => {
                                                    setFormAuthData({
                                                        ...formAuthData,
                                                        registrationDate:
                                                            e.format(
                                                                'YYYY-MM-DD'
                                                            ),
                                                    })
                                                }}
                                                disableFuture
                                                format="YYYY-MM-DD"
                                                value={
                                                    formAuthData.registrationDate
                                                        ? dayjs(
                                                              formAuthData.registrationDate
                                                          )
                                                        : null
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        required
                                                        fullWidth
                                                        size="medium"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel>Status</InputLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="status"
                                        value={formAuthData.status}
                                        onChange={handleInputChange}
                                    >
                                        <FormControlLabel
                                            value="active"
                                            control={<Radio />}
                                            label="Active"
                                        />
                                        <FormControlLabel
                                            value="inactive"
                                            control={<Radio />}
                                            label="Inactive"
                                        />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="statusDate"
                                        name="statusDate"
                                        label="Status Date"
                                        fullWidth
                                        size="medium"
                                        autoComplete="on"
                                        variant="filled"
                                        disabled="true"
                                        value={
                                            new Date()
                                                .toISOString()
                                                .split('T')[0]
                                        }
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="focalPerson"
                                        name="focalPerson"
                                        label="Focal Person"
                                        fullWidth
                                        size="medium"
                                        autoComplete="on"
                                        variant="outlined"
                                        value={formAuthData.focalPerson}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="focalPersonHandphone"
                                        name="focalPersonHandphone"
                                        label="Focal Person Handphone"
                                        fullWidth
                                        size="medium"
                                        type="number"
                                        autoComplete="on"
                                        variant="outlined"
                                        value={
                                            formAuthData.focalPersonHandphone
                                        }
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="flex-end"
                                >
                                    <Grid item>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className="submit-btn"
                                            disabled={authLoading}
                                        >
                                            {authLoading ? (
                                                <CircularProgress
                                                    size={24}
                                                    color="inherit"
                                                />
                                            ) : (
                                                'Update Authorization'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </>
                )}
            </Box>
        </>
    )
}

const RestaurantsTabs = () => {
    return (
        <CustomTab
            Tab1={RestaurantForm}
            tabLabel1="Restaurant Form"
            tabLabel2="Restaurant List"
            tableHeads={restaurantTableHeads}
        />
    )
}
export default RestaurantsTabs
