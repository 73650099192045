import * as React from "react";
import { AreaTable, AreaTop, AreaCards } from "../../components";


const  Reviews =()=> {
 
  return (
    <div className="content-area">
      <AreaTop/>
            <AreaCards />
    <AreaTable/>
   
    </div>
  );
}

export default Reviews ;