import * as React from 'react'
import { AreaTop, Table } from '../../components'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'

const CustomTab = ({ Tab1, tabLabel1, tabLabel2, tableHeads }) => {
    const [tabValue, setTabValue] = React.useState(0)

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
        // window.location.href = window.location.origin + window.location.pathname
    }

    return (
        <React.Fragment>
            <AreaTop />
            <Paper elevation={5}>
                <Box sx={{ padding: 5 }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        className="customTab"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around !important',
                        }}
                    >
                        <Tab label={tabLabel1} />
                        <Tab label={tabLabel2} />
                    </Tabs>
                    {tabValue === 0 && <Tab1 />}
                    {tabValue === 1 && (
                        <Table
                            TableHeads={tableHeads}
                            setTabValue={setTabValue}
                            tabValue={tabValue}
                        />
                    )}
                </Box>
            </Paper>
        </React.Fragment>
    )
}

export default CustomTab
