import { MdOutlineMenu } from 'react-icons/md'
import './AreaTop.scss'
import { useContext } from 'react'
import { SidebarContext } from '../../../context/SidebarContext'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { CustomAlert } from '../../../components'

const AreaTop = () => {
    const { openSidebar } = useContext(SidebarContext)

    const headerName = () => {
        const pathname = window.location.pathname
        if (pathname === '/restaurant') {
            return 'Restaurant'
        } else if (pathname === '/product') {
            return 'Product'
        } else if (pathname === '/product-category') {
            return 'Product Category'
        } else if (pathname === '/supplier') {
            return 'Supplier'
        } else if (pathname === '/dashboard') {
            return 'Dashboard'
        } else if (pathname === '/shipping') {
            return 'Shipping'
        } else if (pathname === '/reviews') {
            return 'Reviews'
        } else if (pathname === '/payment') {
            return 'Payment'
        } else if (pathname === '/users') {
            return 'Users'
        } else if (pathname === '/order-list') {
            return 'Order List'
        }
    }

    return (
        <section className="content-area-top">
            <div className="area-top-l">
                <button
                    className="sidebar-open-btn"
                    type="button"
                    onClick={openSidebar}
                >
                    <MdOutlineMenu size={24} />
                </button>
                <h2 className="area-top-title">{headerName()}</h2>
            </div>
            <div className="area-top-r"></div>
        </section>
    )
}

export default AreaTop
