import * as React from 'react'
import { AreaTable, AreaTop, AreaCards } from '../../components'
import NivoBarChart from '../../components/nivoBarChart/NivoBarChart'
import Grid from '@mui/material/Grid'

const Order = () => {
    return (
        <div className="content-area">
            <AreaCards />
            <AreaTable />
            <Grid container spacing={5}>
                <Grid item xs={5}>
                    {/* Content for column 1 */}
                    <NivoBarChart name="Top Selling Products" />
                </Grid>
            </Grid>
        </div>
    )
}

export default Order
