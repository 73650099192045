import React from 'react';
import { AreaTop } from '../../components';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Image } from '@mui/icons-material';
import masterCard from "../../assets/icons/master-card.png";
import visaElectron from "../../assets/icons/visa-electron.png";
import payPal from "../../assets/icons/payPal.png";
import amazonImage from "../../assets/icons/amazonImage.jpg";
import googlePay from "../../assets/icons/googlePay.png";
import applePay from "../../assets/icons/applePay.png";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const Payment = () => {
  return (
    <React.Fragment>
      <AreaTop />
      <Paper elevation={5}>
        <Box sx={{ padding: 5 }}>
          <h4 style={{ marginBottom: "25px" }}>Payment Information</h4>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="paymentMethodName"
                name="paymentMethodName"
                label="Payment Method Name"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="apiKey"
                name="apiKey"
                label="API Key"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} justifyContent="center" alignItems="center" style={{ marginTop: "40px",padding: "20px"}}>
             <Grid item xs={12} sm={4}>
              <div style={{ position: "relative" }}>
                <img src={masterCard} width={350} height={200}style={{ border: "1px solid #ccc", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }} />
                <CheckIcon style={{ position: "absolute", bottom: 5, left : "0px" }} />
              </div>
            </Grid>
             <Grid item xs={12} sm={4}>
              <div style={{ position: "relative" }}>
                <img src={visaElectron} width={350} height={200} style={{ border: "1px solid #ccc", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }} />
                <CheckIcon style={{ position: "absolute", bottom: 5, left : "0px" }} />
              </div>
            </Grid>
             <Grid item xs={12} sm={4}>
              <div style={{ position: "relative" }}>
                <img src={payPal} width={350} height={200} style={{ border: "1px solid #ccc", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }} />
                <CheckIcon style={{ position: "absolute", bottom: 5, left : "0px" }} />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3} justifyContent="center" alignItems="center" style={{ marginTop: "20px",padding: "20px"}}>
             <Grid item xs={12} sm={4}>
              <div style={{ position: "relative" }}>
                <img src={amazonImage} width={350} height={200} style={{ border: "1px solid #ccc", padding : "20px",boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }} />
                <CloseIcon style={{ position: "absolute", bottom: 5, left : "0px" }} />
              </div>
            </Grid>
             <Grid item xs={12} sm={4}>
              <div style={{ position: "relative" }}>
                <img src={googlePay} width={350} height={200}style={{ border: "1px solid #ccc", padding : "30px",boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }} />
                <CheckIcon style={{ position: "absolute", bottom: 5, left : "0px" }} />
              </div>
            </Grid>
             <Grid item xs={12} sm={4}>
              <div style={{ position: "relative" }}>
                <img src={applePay} width={350}height={200} style={{ border: "1px solid #ccc", padding : "30px",boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }} />
                <CloseIcon style={{ position: "absolute", bottom: 5, left : "0px" }} />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </React.Fragment>
  );
}

export default Payment;
