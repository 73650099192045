import * as React from 'react'
import { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import axios from 'axios'
import { BACKEND_URL, shippingTableHeads } from '../../constant'
import { CustomTab, CustomAlert } from '../../components'
import CircularProgress from '@mui/material/CircularProgress'
import { useLocation } from 'react-router-dom'
import { getParamsFromLocalStorage } from '../../global/index.jsx'

const ShippingForm = () => {
    const location = useLocation()
    const [loading, setLoading] = React.useState(false)
    const [alert, setAlert] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState('')
    const [severity, setSeverity] = useState('')
    const [shippingMethod, setShippingMethod] = useState('')
    const [formData, setFormData] = useState({
        shippingId: '',
        shippingMethodName: '',
        apiKey: '',
    })

    useEffect(() => {
        const params = getParamsFromLocalStorage()
        setFormData({
            shippingId: params.get('ShippingID') || '',
            shippingMethodName: params.get('ShippingMethod') || '',
            apiKey: params.get('APIKey') || '',
        })
    }, [localStorage.getItem('searchParams')])

    const handleSubmit = async (event) => {
        event.preventDefault()

        setLoading(true)
        axios
            .post(`${BACKEND_URL}/addShippingMethod`, formData)
            .then((response) => {
                setLoading(false)
                setFormData({
                    ...formData,
                    shippingId: response.data.shippingId,
                })
                setAlert(true)
                setAlertMessage('Shipping Method Added Successfully!')
                setSeverity('success')
            })
            .catch((error) => {
                setLoading(false)
                setAlert(true)
                setAlertMessage('Error: ' + error)
                setSeverity('error')
            })
    }

    const handleReset = () => {
        setFormData({
            shippingMethodName: '',
            apiKey: '',
        })
        localStorage.removeItem('searchParams')
    }

    const handleUpdate = async (event) => {
        event.preventDefault()

        setLoading(true)
        axios
            .put(
                `${BACKEND_URL}/updateShipping/${formData.shippingId}`,
                formData
            )
            .then((response) => {
                setLoading(false)
                setAlert(true)
                setAlertMessage('Shipping Updated Successfully!')
                setSeverity('success')

                localStorage.removeItem('searchParams')
            })
            .catch((error) => {
                setLoading(false)
                setAlert(true)
                setAlertMessage('Error: ' + error)
                setSeverity('error')
            })
    }

    return (
        <>
            {alert && (
                <CustomAlert
                    message={alertMessage}
                    severity={severity}
                    setAlert={setAlert}
                    style={{ zIndex: 99999 }}
                />
            )}
            <Box sx={{ padding: 5 }}>
                <h4 style={{ marginBottom: '25px' }}>Shipping Setup</h4>
                <form onSubmit={handleSubmit} className="basic-shipping-form">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="shippingMethodName"
                                name="shippingMethodName"
                                label="Shipping Method Name"
                                fullWidth
                                size="small"
                                autoComplete="off"
                                variant="outlined"
                                value={formData.shippingMethodName}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        shippingMethodName: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="apiKey"
                                name="apiKey"
                                label="API Key"
                                fullWidth
                                size="small"
                                autoComplete="off"
                                variant="outlined"
                                value={formData.apiKey}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        apiKey: e.target.value,
                                    })
                                }
                            />
                        </Grid>

                        {/* Buttons */}
                        {localStorage.getItem('searchParams') && (
                            <>
                                <Grid item xs={12} sm={2}>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        onClick={handleReset}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={handleUpdate}
                                    >
                                        Update
                                    </Button>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} sm={4}>
                            {!localStorage.getItem('searchParams') && (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    style={{ backgroundColor: '#7056b5' }}
                                >
                                    {loading ? (
                                        <CircularProgress
                                            size={24}
                                            color="inherit"
                                        />
                                    ) : (
                                        'Add'
                                    )}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </>
    )
}

const ShippingTabs = () => {
    return (
        <CustomTab
            Tab1={ShippingForm}
            tabLabel1="Shipping Form"
            tabLabel2="Shipping List"
            tableHeads={shippingTableHeads}
        />
    )
}

export default ShippingTabs
