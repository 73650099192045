export const BACKEND_URL = 'https://backend.cocoa-app.com'
// export const BACKEND_URL = 'http://localhost:8081'

export const restaurantTableHeads = [
    'RestaurantID',
    'UserID',
    'RestaurantName',
    'CompanyName',
    'CompanyRegNo',
    'Address',
    'Email',
    'Phone',
    'CompanyRegDate',
    'City',
    'State',
    'ZipCode',
    'Country',
    'RegistrationDate',
    'Status',
    'StatusDate',
    'FocalPerson',
    'FocalPersonHp',
]
export const productTableHeads = [
    'MainImage',
    'MultiImages',
    'ProductID',
    'ProductBarcode',
    'UOM',
    'ProductName',
    'CategoryID',
    'UnitPrice',
    'UnitsInStock',
    'Description',
    'SupplierID',
    'DateAdded',
]
export const supplierTableHeads = [
    'SupplierID',
    'UserID',
    'CompanyName',
    'CompanyRegNo',
    'Address',
    'Email',
    'Phone',
    'CompanyRegDate',
    'City',
    'State',
    'ZipCode',
    'Country',
    'Status',
    'StatusDate',
    'FocalPerson',
    'FocalPersonHP',
]

export const shippingTableHeads = ['ShippingID', 'ShippingMethod', 'APIKey']

export const categoryTableHeads = ['categoryId', 'categoryName']
