import Card from '../../card/card'
import Restaurant from '../../../assets/icons/Restaurant.png'
import Category from '../../../assets/icons/Categories.png'
import Products from '../../../assets/icons/Products.png'
import Supplier from '../../../assets/icons/Supplier.png'
import Orders from '../../../assets/icons/Order.png'
import Payment from '../../../assets/icons/Payment.png'
import Shipping from '../../../assets/icons/Shipping.png'
import Reviews from '../../../assets/icons/Reviews.png'
import Users from '../../../assets/icons/Users.png'
import './AreaCharts.scss'

const AreaBarChart = () => {
    return (
        <section className="content-area-charts">
            <div className="cards-row">
                <Card
                    name="Restaurant"
                    imageUrl={Restaurant}
                    url="restaurant"
                />
                <Card
                    name="Category"
                    imageUrl={Category}
                    url="product-category"
                />
                <Card name="Product" imageUrl={Products} url="product" />
                <Card name="Supplier" imageUrl={Supplier} url="supplier" />
                <Card name="Orders" imageUrl={Orders} url="order-list" />
            </div>
            <div className="cards-row">
                <Card name="Payment" imageUrl={Payment} url="payment" />
                <Card name="Shipping" imageUrl={Shipping} url="shipping" />
                <Card name="Reviews" imageUrl={Reviews} url="reviews" />
                <Card name="Users" imageUrl={Users} url="users" />
            </div>
        </section>
    )
}

export default AreaBarChart
