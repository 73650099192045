import { BACKEND_URL } from '../constant'
import axios from 'axios'

export const getCategories = () => {
    return axios
        .get(`${BACKEND_URL}/getCategories`)
        .then((response) => {
            const categories = response.data

            return categories
        })
        .catch((error) => {
            console.error('Error fetching categories:', error)
            throw error // Propagate the error so it can be handled by the caller
        })
}

export const getSuppliers = () => {
    return axios
        .get(`${BACKEND_URL}/getSuppliers`)
        .then((response) => {
            const suppliers = response.data
            console.log('Suppliers : ', suppliers)
            return suppliers
        })
        .catch((error) => {
            console.error('Error fetching categories:', error)
            throw error // Propagate the error so it can be handled by the caller
        })
}

export const addProduct = (productFormData) => {
    return axios
        .post(`${BACKEND_URL}/addProduct`, productFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        .then((response) => {
            return response.data
        })
        .catch((error) => {
            console.error('Error fetching categories:', error)
            throw error // Propagate the error so it can be handled by the caller
        })
}

export const updateProduct = (productFormData, ProductId) => {
    return axios
        .put(`${BACKEND_URL}/updateProduct/${ProductId}`, productFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            console.error('Error fetching categories:', error)
            throw error // Propagate the error so it can be handled by the caller
        })
}
