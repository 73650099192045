import * as React from 'react'
import { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import CardComponent from '../../components/card/card.jsx'
import { RiImageAddFill } from 'react-icons/ri'
import * as imageConversion from 'image-conversion'
import Category from '../../assets/icons/Categories.png'
import Supplier from '../../assets/icons/Supplier.png'
import './Product.scss'
import { CustomAlert, CustomTab } from '../../components'
import dayjs from 'dayjs'
import { getParamsFromLocalStorage } from '../../global/index.jsx'
import { BACKEND_URL, productTableHeads } from '../../constant.js'
import CircularProgress from '@mui/material/CircularProgress'
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
} from '@mui/material'

import {
    getCategories,
    addProduct,
    getSuppliers,
    updateProduct,
} from '../../endpoints/endpoints'

const ProductForm = () => {
    const [categories, setCategories] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [alert, setAlert] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState()
    const [severity, setSeverity] = useState()
    const [loading, setLoading] = useState(false)
    const [listLoading, setListLoading] = useState(false)
    const [fieldsDisabled, setFieldsDisabled] = useState(false)
    const [products, setProducts] = useState([])
    const [mainCardImage, setMainCardImage] = useState(null)
    const [mainImage, setMainImage] = useState(null)
    const [updateBtnText, setUpdateBtnText] = useState(false)
    const [submittedProduct, setSubmittedProduct] = useState(false)
    const [productImages, setProductImages] = useState([null, null, null])
    const [productCardImages, setProductCardImages] = useState([
        null,
        null,
        null,
    ])

    const [formData, setFormData] = useState({
        productId: 'Auto Generated',
        productBarcode: '',
        uom: '',
        productName: '',
        category: '',
        unitPrice: '',
        unitInStock: '',
        description: '',
        supplier: '',
        dateAdded: dayjs(new Date()).format('YYYY-DD-MM'),
        mainImage: mainImage,
        productImages: productImages,
    })

    useEffect(() => {
        const params = getParamsFromLocalStorage()
        const baseURL = `${BACKEND_URL}/images/`

        const urlProductImages = params.get('MultiImages') || ''

        if (urlProductImages.includes(',')) {
            const imagesArray = urlProductImages
                .split(',')
                .map((img) => baseURL + img)
            setProductCardImages([
                imagesArray[0] || null,
                imagesArray[1] || null,
                imagesArray[2] || null,
            ])
        } else if (urlProductImages) {
            const singleImageURL = baseURL + urlProductImages
            // setProductImages([singleImageURL, null, null])
            setProductCardImages([singleImageURL, null, null])
        }

        // setMainCardImage(baseURL + params.get('MainImage'))
        setMainCardImage(`${BACKEND_URL}/images/` + params.get('MainImage'))
        setFormData({
            productId: params.get('ProductID') || '',
            productBarcode: params.get('ProductBarcode') || '',
            uom: params.get('UOM') || '',
            productName: params.get('ProductName') || '',
            category: params.get('CategoryID') || '',
            unitPrice: params.get('UnitPrice') || '',
            unitInStock: params.get('UnitsInStock') || '',
            description: params.get('Description') || '',
            supplier: params.get('SupplierID') || '',
            dateAdded: params.get('DateAdded') || '',
            mainImage: setMainImage(params.get('MainImage') || ''),
            productImages: setProductImages(
                params.get('MultiImages')
                    ? params
                          .get('MultiImages')
                          .split(',')
                          .map((img) => img)
                    : []
            ),
        })
    }, [localStorage.getItem('searchParams')])

    const handleMainImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => setMainCardImage(e.target.result)
            reader.readAsDataURL(event.target.files[0])
        }
        setMainImage(event.target.files[0])
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    // const handleProductImageChange = (index) => (event) => {
    //     if (event.target.files && event.target.files[0]) {
    //         const reader = new FileReader()
    //         reader.onload = (e) => {
    //             const newProductImages = [...productImages]
    //             newProductImages[index] = e.target.result
    //             setProductCardImages(newProductImages)
    //         }
    //         reader.readAsDataURL(event.target.files[0])

    //         const newProductImages = [...productImages]
    //         newProductImages[index] = event.target.files[0]

    //         setProductImages(newProductImages)
    //     }
    // }

    const handleProductImageChange = (index) => (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            const reader = new FileReader()

            reader.onload = (e) => {
                const newProductCardImages = [...productCardImages]
                newProductCardImages[index] = e.target.result

                setProductCardImages(newProductCardImages)
            }

            reader.readAsDataURL(file)

            const newProductImages = [...productImages]
            newProductImages[index] = file

            setProductImages(newProductImages)
        }
    }

    const fetchCategories = async () => {
        try {
            const categories = await getCategories()
            setCategories(categories)
            console.log('Categories:', categories)
            // Do something with the categories
        } catch (error) {
            console.error('Error:', error)
        }
    }
    const fetchSuppliers = async () => {
        try {
            const suppliers = await getSuppliers()
            setSuppliers(suppliers)
            // Do something with the categories
        } catch (error) {
            console.error('Error:', error)
        }
    }

    setTimeout(() => {
        setListLoading(false)
    }, 1200)

    React.useEffect(() => {
        let timer
        if (alert) {
            timer = setTimeout(() => {
                setAlert(false)
            }, 10000)
        }
        return () => clearTimeout(timer)
    }, [alert])

    React.useEffect(() => {
        const fetchData = async () => {
            setListLoading(true)
            await fetchCategories()
            await fetchSuppliers()
            setListLoading(false)
        }

        fetchData()
    }, [products])

    React.useEffect(() => {
        if (localStorage.getItem('searchParams')) {
            setSubmittedProduct(true)
            setUpdateBtnText(true)
        } else {
            setUpdateBtnText(false)
            setSubmittedProduct(false)
        }
    }, [localStorage.getItem('searchParams')])

    const handleSubmit = async (event) => {
        event.preventDefault()

        // Add state variables to formDataObject
        // formData.dateAdded = '2024-06-17'
        formData.dateAdded = new Date()?.toISOString().split('T')[0]
        formData.mainImage = mainImage
        formData.productImages = productImages // For category

        if (document.querySelector('.submit-btn').firstChild.data === 'Edit') {
            //Edit Button
            setUpdateBtnText(true)
            setFieldsDisabled(false)
            // setSubmittedRestaurant(false)
        } else if (
            document.querySelector('.submit-btn').firstChild.data === 'Update'
        ) {
            try {
                setLoading(true)
                const response = await updateProduct(
                    formData,
                    formData.productId
                )
                setProducts(response.data)
                console.log('Response:', response)

                setLoading(false)
                setAlert(true)
                setAlertMessage('Successfully Updated Product!')
                setSeverity('success')
                setFieldsDisabled(true)
                setUpdateBtnText(false)
                // Do something with the response
            } catch (e) {
                setLoading(false)
                setAlert(true)
                setAlertMessage(
                    e.response.data['error'].includes('ER_DUP_ENTRY')
                        ? 'Product Barcode Already Exist'
                        : 'Failed To Update Product!'
                )
                setSeverity('error')
                console.error('Error:', e)
            }
        } else {
            try {
                setLoading(true)
                const response = await addProduct(formData)
                setProducts(response.data)
                console.log('Response:', response)
                setFieldsDisabled(true)
                setSubmittedProduct(true)
                setFormData({ ...formData, productId: response.productId })
                setLoading(false)
                setAlert(true)
                setAlertMessage('Successfully Added Product!')
                setSeverity('success')
                // Do something with the response
            } catch (e) {
                setLoading(false)
                setAlert(true)
                setAlertMessage('Failed To Add Product!')
                setSeverity('error')
                console.error('Error:', e)
            }
        }
    }

    return (
        <>
            {alert && (
                <CustomAlert
                    message={alertMessage}
                    severity={severity}
                    setAlert={setAlert}
                    style={{ zIndex: 99999 }}
                />
            )}

            {listLoading ? (
                <CircularProgress size={24} color="inherit" />
            ) : (
                <>
                    {(categories && suppliers && categories.length === 0) ||
                    suppliers.length === 0 ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginBottom: '56px',
                                flexDirection: 'column-reverse',
                                alignContent: 'space-around',
                                flexWrap: 'wrap',
                            }}
                        >
                            {categories &&
                                categories.length === 0 &&
                                !listLoading && (
                                    <>
                                        <h4>
                                            Category Needs To Be Added First
                                        </h4>
                                        <CardComponent
                                            name="Category"
                                            imageUrl={Category}
                                            url="product-category"
                                        />
                                    </>
                                )}
                            {suppliers &&
                                suppliers.length === 0 &&
                                !listLoading && (
                                    <>
                                        <h4>
                                            Supplier Needs To Be Added First
                                        </h4>
                                        <CardComponent
                                            name="Supplier"
                                            imageUrl={Supplier}
                                            url="supplier"
                                        />
                                    </>
                                )}
                        </div>
                    ) : (
                        <React.Fragment>
                            <Box sx={{ padding: 5 }}>
                                <h4 style={{ marginBottom: '15px' }}>
                                    Product Setup
                                </h4>
                                <form
                                    onSubmit={handleSubmit}
                                    enctype="multipart/form-data"
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                id="filled-disabled"
                                                name="productID"
                                                label="Product ID"
                                                fullWidth
                                                variant="filled"
                                                value={formData.productId}
                                                onChange={handleChange}
                                                size="medium"
                                                autoComplete="off"
                                                disabled
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                required
                                                id="productBarcode"
                                                name="productBarcode"
                                                label="Product Barcode"
                                                fullWidth
                                                size="medium"
                                                autoComplete="off"
                                                value={formData.productBarcode}
                                                onChange={handleChange}
                                                variant="outlined"
                                                disabled={fieldsDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormControl
                                                fullWidth
                                                size="medium"
                                            >
                                                <InputLabel id="demo-simple-select-label">
                                                    UOM
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="uom"
                                                    name="uom"
                                                    value={formData.uom}
                                                    label="UOM"
                                                    onChange={handleChange}
                                                    disabled={fieldsDisabled}
                                                >
                                                    <MenuItem value={'Unit'}>
                                                        Unit
                                                    </MenuItem>
                                                    <MenuItem value={'Litre'}>
                                                        Litre
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={'Kilogram'}
                                                    >
                                                        KG
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                required
                                                id="productName"
                                                name="productName"
                                                label="Product Name"
                                                fullWidth
                                                size="medium"
                                                autoComplete="off"
                                                value={formData.productName}
                                                onChange={handleChange}
                                                variant="outlined"
                                                disabled={fieldsDisabled}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <FormControl
                                                fullWidth
                                                size="medium"
                                            >
                                                <InputLabel id="demo-simple-select-label">
                                                    Category Id
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="categoryId"
                                                    name="category"
                                                    label="Category Id"
                                                    value={formData.category}
                                                    onChange={handleChange}
                                                    disabled={
                                                        (formData.category &&
                                                            formData.category
                                                                .length ===
                                                                0) ||
                                                        fieldsDisabled
                                                    }
                                                >
                                                    {categories.map(
                                                        (item, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={
                                                                    item.categoryId
                                                                }
                                                            >
                                                                {
                                                                    item.categoryName
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                required
                                                id="unitPrice"
                                                name="unitPrice"
                                                label="Unit Price"
                                                fullWidth
                                                size="medium"
                                                value={formData.unitPrice}
                                                onChange={handleChange}
                                                autoComplete="off"
                                                variant="outlined"
                                                disabled={fieldsDisabled}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                required
                                                id="unitInStock"
                                                name="unitInStock"
                                                label="Unit In Stock"
                                                fullWidth
                                                size="medium"
                                                autoComplete="off"
                                                value={formData.unitInStock}
                                                onChange={handleChange}
                                                variant="outlined"
                                                disabled={fieldsDisabled}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                required
                                                id="description"
                                                name="description"
                                                label="Description"
                                                fullWidth
                                                size="medium"
                                                autoComplete="off"
                                                value={formData.description}
                                                onChange={handleChange}
                                                variant="outlined"
                                                disabled={fieldsDisabled}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                fullWidth
                                                size="medium"
                                            >
                                                <InputLabel id="demo-simple-select-label">
                                                    Supplier Id
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="supplierId"
                                                    name="supplier"
                                                    label="Supplier Id"
                                                    value={formData.supplier}
                                                    onChange={handleChange}
                                                    disabled={
                                                        (formData.supplier &&
                                                            formData.supplier
                                                                .length ===
                                                                0) ||
                                                        fieldsDisabled
                                                    }
                                                >
                                                    {suppliers.map(
                                                        (item, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={
                                                                    item.SupplierID
                                                                }
                                                            >
                                                                {
                                                                    item.CompanyName
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                            {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                            >
                                                <DemoContainer
                                                    components={['DatePicker']}
                                                >
                                                    <DatePicker
                                                        size="medium"
                                                        label="Date Added"
                                                        format={'YYYY-MM-DD'}
                                                        value={
                                                            formData.dateAdded
                                                                ? dayjs(
                                                                      formData.dateAdded
                                                                  )
                                                                : null
                                                        }
                                                        disabled={true}
                                                        // disabled={fieldsDisabled}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                required
                                                                size="medium"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <h4
                                                style={{
                                                    marginBottom: '15px',
                                                    marginTop: '15px',
                                                }}
                                            >
                                                Product Images
                                            </h4>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: '10px',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Card
                                                    sx={{
                                                        width: 200,
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <label htmlFor="main-image-upload">
                                                        {mainCardImage ? (
                                                            <CardMedia
                                                                component="img"
                                                                height="140"
                                                                image={
                                                                    mainCardImage
                                                                }
                                                                alt="Main Image"
                                                            />
                                                        ) : (
                                                            <RiImageAddFill
                                                                size={100}
                                                                height={140}
                                                            />
                                                        )}
                                                    </label>
                                                    <CardContent>
                                                        <Typography
                                                            gutterBottom
                                                            variant="h6"
                                                            component="div"
                                                        >
                                                            Main Image
                                                        </Typography>
                                                        <input
                                                            name="mainImage"
                                                            id="main-image-upload"
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={
                                                                handleMainImageChange
                                                            }
                                                            disabled={
                                                                fieldsDisabled
                                                            }
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                        />
                                                    </CardContent>
                                                </Card>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        gap: '15px',
                                                    }}
                                                >
                                                    {productCardImages.map(
                                                        (image, index) => (
                                                            <Card
                                                                key={index}
                                                                sx={{
                                                                    width: 120,
                                                                    display:
                                                                        'flex',
                                                                    flexWrap:
                                                                        'wrap',
                                                                    justifyContent:
                                                                        'center',
                                                                }}
                                                            >
                                                                <label
                                                                    htmlFor={`product-image-upload-${index}`}
                                                                >
                                                                    {image ? (
                                                                        <CardMedia
                                                                            component="img"
                                                                            height="100"
                                                                            image={
                                                                                image ||
                                                                                '/path/to/placeholder/image.png'
                                                                            }
                                                                            alt={`${
                                                                                index +
                                                                                1
                                                                            }`}
                                                                        />
                                                                    ) : (
                                                                        <div>
                                                                            <RiImageAddFill
                                                                                size={
                                                                                    50
                                                                                }
                                                                                height={
                                                                                    70
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </label>
                                                                <CardContent>
                                                                    <Typography
                                                                        gutterBottom
                                                                        variant="h6"
                                                                        component="div"
                                                                    >
                                                                        Image{' '}
                                                                        {index +
                                                                            1}
                                                                    </Typography>
                                                                    <input
                                                                        id={`product-image-upload-${index}`}
                                                                        type="file"
                                                                        accept="image/*"
                                                                        onChange={handleProductImageChange(
                                                                            index
                                                                        )}
                                                                        style={{
                                                                            display:
                                                                                'none',
                                                                        }}
                                                                        disabled={
                                                                            fieldsDisabled
                                                                        }
                                                                    />
                                                                </CardContent>
                                                            </Card>
                                                        )
                                                    )}
                                                </div>
                                                {/* <div
                                                    style={{
                                                        display: 'flex',
                                                        gap: '15px',
                                                    }}
                                                >
                                                    {productCardImages.map(
                                                        (image, index) => (
                                                            <Card
                                                                key={index}
                                                                sx={{
                                                                    width: 120,
                                                                    display:
                                                                        'flex',
                                                                    flexWrap:
                                                                        'wrap',
                                                                    justifyContent:
                                                                        'center',
                                                                }}
                                                            >
                                                                <label
                                                                    htmlFor={`product-image-upload-${index}`}
                                                                >
                                                                    {image ? (
                                                                        <CardMedia
                                                                            component="img"
                                                                            height="100"
                                                                            image={
                                                                                image ||
                                                                                '/path/to/placeholder/image.png'
                                                                            }
                                                                            alt={`${
                                                                                index +
                                                                                1
                                                                            }`}
                                                                        />
                                                                    ) : (
                                                                        <div>
                                                                            <RiImageAddFill
                                                                                size={
                                                                                    50
                                                                                }
                                                                                height={
                                                                                    70
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </label>
                                                                <CardContent>
                                                                    <Typography
                                                                        gutterBottom
                                                                        variant="h6"
                                                                        component="div"
                                                                    >
                                                                        Image{' '}
                                                                        {index +
                                                                            1}
                                                                    </Typography>
                                                                    <input
                                                                        id={`product-image-upload-${index}`}
                                                                        type="file"
                                                                        accept="image/*"
                                                                        onChange={handleProductImageChange(
                                                                            index
                                                                        )}
                                                                        style={{
                                                                            display:
                                                                                'none',
                                                                        }}
                                                                    />
                                                                </CardContent>
                                                            </Card>
                                                        )
                                                    )}
                                                </div> */}
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="flex-end"
                                        >
                                            <Grid item sx={{ mr: 1 }}>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    className="reset-btn"
                                                    // onClick={clearForm}
                                                >
                                                    Reset
                                                </Button>
                                            </Grid>

                                            <Grid item>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className="submit-btn"
                                                >
                                                    {loading ? (
                                                        <CircularProgress
                                                            size={24}
                                                            color="inherit"
                                                        />
                                                    ) : submittedProduct ? (
                                                        updateBtnText ? (
                                                            'Update'
                                                        ) : (
                                                            'Edit'
                                                        )
                                                    ) : (
                                                        'Submit'
                                                    )}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </React.Fragment>
                    )}
                </>
            )}
        </>
    )
}

const ProductTabs = () => {
    return (
        <CustomTab
            Tab1={ProductForm}
            tabLabel1="Product Form"
            tabLabel2="Product List"
            tableHeads={productTableHeads}
        />
    )
}
export default ProductTabs
