import Alert from '@mui/material/Alert'
import CheckIcon from '@mui/icons-material/Check'

const CustomAlert = ({ message, severity, setAlert }) => {
    return (
        <div className="custom-alert">
            <Alert icon={<CheckIcon fontSize="inherit" />} severity={severity}>
                {message}
            </Alert>
        </div>
    )
}

export default CustomAlert
