import * as React from 'react'
import { useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import axios from 'axios'
import { BACKEND_URL, categoryTableHeads } from '../../constant'
import { CustomTab, CustomAlert } from '../../components'
import CircularProgress from '@mui/material/CircularProgress'

const ProductCategory = () => {
    const [formData, setFormData] = useState({
        categoryId: 'Auto Generated',
        categoryName: '',
    })
    const [loading, setLoading] = React.useState(false)
    const [alert, setAlert] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState()
    const [severity, setSeverity] = useState()

    React.useEffect(() => {
        let timer
        if (alert) {
            timer = setTimeout(() => {
                setAlert(false)
            }, 10000)
        }
        return () => clearTimeout(timer)
    }, [alert])

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const addCategoryButton = () => {
        setLoading(true)
        axios
            .post(`${BACKEND_URL}/addProductCategory`, formData)
            .then((response) => {
                setLoading(false)
                setAlert(true)
                setAlertMessage('Product Category Added Successfully!')
                setSeverity('success')

                // Optionally, you can reset the form fields after successful addition
                setFormData({
                    categoryId: 'Auto Generated',
                    categoryName: '',
                })
            })
            .catch((error) => {
                setLoading(false)
                setAlert(true)
                setAlertMessage('Error: ' + error)
                setSeverity('error')
            })
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            addCategoryButton()
        }
    }

    return (
        <>
            {alert && (
                <CustomAlert
                    message={alertMessage}
                    severity={severity}
                    setAlert={setAlert}
                    style={{ zIndex: 99999 }}
                />
            )}
            <Box sx={{ padding: 5 }}>
                <h4 style={{ marginBottom: '25px' }}>Category Information</h4>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={10}>
                        <TextField
                            required
                            id="categoryName"
                            name="categoryName"
                            label="Category Name"
                            fullWidth
                            size="small"
                            autoComplete="off"
                            variant="outlined"
                            value={formData.categoryName}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                        />
                    </Grid>

                    {/* Add Button */}
                    <Grid item xs={12} sm={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => addCategoryButton()}
                            style={{ backgroundColor: '#7056b5' }}
                            disabled={
                                formData.categoryName !== '' ? false : true
                            }
                        >
                            {loading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                'Add'
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

const ProductTabs = () => {
    return (
        <CustomTab
            Tab1={ProductCategory}
            tabLabel1="Product Category Form"
            tabLabel2="Product Category List"
            tableHeads={categoryTableHeads}
        />
    )
}

export default ProductTabs
