import { ResponsiveLine } from '@nivo/line'
import "./NivoLineChart.scss"

const data =[
  {
    "id": "japan",
    "color": "hsl(96, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 17
      },
      {
        "x": "helicopter",
        "y": 80
      },
      {
        "x": "boat",
        "y": 20
      },
      {
        "x": "train",
        "y": 293
      },
      {
        "x": "subway",
        "y": 18
      },
      {
        "x": "bus",
        "y": 232
      },
      {
        "x": "car",
        "y": 192
      },
      {
        "x": "moto",
        "y": 187
      },
      {
        "x": "bicycle",
        "y": 194
      },
      {
        "x": "horse",
        "y": 218
      },
      {
        "x": "skateboard",
        "y": 170
      },
      {
        "x": "others",
        "y": 273
      }
    ]
  },
  {
    "id": "france",
    "color": "hsl(264, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 87
      },
      {
        "x": "helicopter",
        "y": 230
      },
      {
        "x": "boat",
        "y": 129
      },
      {
        "x": "train",
        "y": 204
      },
      {
        "x": "subway",
        "y": 154
      },
      {
        "x": "bus",
        "y": 31
      },
      {
        "x": "car",
        "y": 291
      },
      {
        "x": "moto",
        "y": 278
      },
      {
        "x": "bicycle",
        "y": 290
      },
      {
        "x": "horse",
        "y": 74
      },
      {
        "x": "skateboard",
        "y": 238
      },
      {
        "x": "others",
        "y": 226
      }
    ]
  },
  {
    "id": "us",
    "color": "hsl(297, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 99
      },
      {
        "x": "helicopter",
        "y": 101
      },
      {
        "x": "boat",
        "y": 10
      },
      {
        "x": "train",
        "y": 211
      },
      {
        "x": "subway",
        "y": 235
      },
      {
        "x": "bus",
        "y": 295
      },
      {
        "x": "car",
        "y": 271
      },
      {
        "x": "moto",
        "y": 57
      },
      {
        "x": "bicycle",
        "y": 65
      },
      {
        "x": "horse",
        "y": 143
      },
      {
        "x": "skateboard",
        "y": 74
      },
      {
        "x": "others",
        "y": 80
      }
    ]
  },
  {
    "id": "germany",
    "color": "hsl(116, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 152
      },
      {
        "x": "helicopter",
        "y": 93
      },
      {
        "x": "boat",
        "y": 117
      },
      {
        "x": "train",
        "y": 81
      },
      {
        "x": "subway",
        "y": 153
      },
      {
        "x": "bus",
        "y": 223
      },
      {
        "x": "car",
        "y": 41
      },
      {
        "x": "moto",
        "y": 114
      },
      {
        "x": "bicycle",
        "y": 270
      },
      {
        "x": "horse",
        "y": 206
      },
      {
        "x": "skateboard",
        "y": 272
      },
      {
        "x": "others",
        "y": 294
      }
    ]
  },
  {
    "id": "norway",
    "color": "hsl(216, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 166
      },
      {
        "x": "helicopter",
        "y": 276
      },
      {
        "x": "boat",
        "y": 39
      },
      {
        "x": "train",
        "y": 77
      },
      {
        "x": "subway",
        "y": 9
      },
      {
        "x": "bus",
        "y": 232
      },
      {
        "x": "car",
        "y": 123
      },
      {
        "x": "moto",
        "y": 24
      },
      {
        "x": "bicycle",
        "y": 190
      },
      {
        "x": "horse",
        "y": 227
      },
      {
        "x": "skateboard",
        "y": 270
      },
      {
        "x": "others",
        "y": 203
      }
    ]
  }
]


const MyResponsiveLine = ({ data }) => (
    <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'transportation',
            legendOffset: 36,
            legendPosition: 'middle',
            truncateTickAt: 0
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'count',
            legendOffset: -40,
            legendPosition: 'middle',
            truncateTickAt: 0
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="data.yFormatted"
        pointLabelYOffset={-12}
        enableTouchCrosshair={true}
        useMesh={true}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
)

const NivoLineChart = () => {
  return (
    <section className="content-area-table">
      <h4 style={{padding: '10px'}}>Sales Chart Current Month</h4>
    <MyResponsiveLine data={data}/>
    </section>
  );
};

export default NivoLineChart;