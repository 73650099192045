import { ResponsiveBar } from '@nivo/bar'
import "../dashboard/nivoLineChart/NivoLineChart.scss"

const data =[
  {
    "country": "AD",
    "hot dog": 0,
    "hot dogColor": "hsl(33, 70%, 50%)",
    "burger": 5,
    "burgerColor": "hsl(38, 70%, 50%)",
    "sandwich": 22,
    "sandwichColor": "hsl(310, 70%, 50%)",
    "kebab": 13,
    "kebabColor": "hsl(101, 70%, 50%)",
    "fries": 67,
    "friesColor": "hsl(53, 70%, 50%)",
    "donut": 108,
    "donutColor": "hsl(14, 70%, 50%)"
  },
  {
    "country": "AE",
    "hot dog": 193,
    "hot dogColor": "hsl(327, 70%, 50%)",
    "burger": 124,
    "burgerColor": "hsl(69, 70%, 50%)",
    "sandwich": 171,
    "sandwichColor": "hsl(182, 70%, 50%)",
    "kebab": 75,
    "kebabColor": "hsl(160, 70%, 50%)",
    "fries": 168,
    "friesColor": "hsl(287, 70%, 50%)",
    "donut": 111,
    "donutColor": "hsl(121, 70%, 50%)"
  },
  {
    "country": "AF",
    "hot dog": 40,
    "hot dogColor": "hsl(230, 70%, 50%)",
    "burger": 22,
    "burgerColor": "hsl(132, 70%, 50%)",
    "sandwich": 174,
    "sandwichColor": "hsl(292, 70%, 50%)",
    "kebab": 146,
    "kebabColor": "hsl(143, 70%, 50%)",
    "fries": 141,
    "friesColor": "hsl(23, 70%, 50%)",
    "donut": 21,
    "donutColor": "hsl(112, 70%, 50%)"
  },
  {
    "country": "AG",
    "hot dog": 76,
    "hot dogColor": "hsl(323, 70%, 50%)",
    "burger": 40,
    "burgerColor": "hsl(355, 70%, 50%)",
    "sandwich": 27,
    "sandwichColor": "hsl(328, 70%, 50%)",
    "kebab": 122,
    "kebabColor": "hsl(305, 70%, 50%)",
    "fries": 153,
    "friesColor": "hsl(268, 70%, 50%)",
    "donut": 59,
    "donutColor": "hsl(264, 70%, 50%)"
  },
  {
    "country": "AI",
    "hot dog": 67,
    "hot dogColor": "hsl(27, 70%, 50%)",
    "burger": 39,
    "burgerColor": "hsl(153, 70%, 50%)",
    "sandwich": 101,
    "sandwichColor": "hsl(320, 70%, 50%)",
    "kebab": 124,
    "kebabColor": "hsl(166, 70%, 50%)",
    "fries": 88,
    "friesColor": "hsl(51, 70%, 50%)",
    "donut": 96,
    "donutColor": "hsl(149, 70%, 50%)"
  },
  {
    "country": "AL",
    "hot dog": 97,
    "hot dogColor": "hsl(25, 70%, 50%)",
    "burger": 10,
    "burgerColor": "hsl(288, 70%, 50%)",
    "sandwich": 9,
    "sandwichColor": "hsl(141, 70%, 50%)",
    "kebab": 153,
    "kebabColor": "hsl(0, 70%, 50%)",
    "fries": 143,
    "friesColor": "hsl(285, 70%, 50%)",
    "donut": 91,
    "donutColor": "hsl(134, 70%, 50%)"
  },
  {
    "country": "AM",
    "hot dog": 22,
    "hot dogColor": "hsl(10, 70%, 50%)",
    "burger": 120,
    "burgerColor": "hsl(112, 70%, 50%)",
    "sandwich": 152,
    "sandwichColor": "hsl(19, 70%, 50%)",
    "kebab": 151,
    "kebabColor": "hsl(124, 70%, 50%)",
    "fries": 134,
    "friesColor": "hsl(305, 70%, 50%)",
    "donut": 102,
    "donutColor": "hsl(324, 70%, 50%)"
  }
]



const MyResponsiveBar = ({ data /* see data tab */ }) => (
    <ResponsiveBar
        data={data}
        keys={[
            'hot dog',
            'burger',
            'sandwich',
            'kebab',
            'fries',
            'donut'
        ]}
        indexBy="country"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        // padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 2,
                // padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 3,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: 'fries'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'sandwich'
                },
                id: 'lines'
            }
        ]}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 2,
            tickPadding: 2,
            tickRotation: 0,
            legend: 'country',
            legendPosition: 'middle',
            legendOffset: 32,
            truncateTickAt: 0
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'food',
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0
        }}
        labelSkipWidth={5}
        labelSkipHeight={5}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
    />
)

const NivoBarChart = ({ name }) => {
  return (
    <section className="content-area-table">
      <h4 style={{padding: '10px'}}>{name}</h4>
    <MyResponsiveBar data={data}/>
    </section>
  );
};

export default NivoBarChart;