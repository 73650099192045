import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { BACKEND_URL } from '../../constant'
import { DataGrid } from '@mui/x-data-grid'
import axios from 'axios'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { CustomAlert } from '../../components'
import Avatar from '@mui/material/Avatar'
import DeleteIcon from '@mui/icons-material/Delete'
import LoadingButton from '@mui/lab/LoadingButton'

const CustomTable = ({ TableHeads, setTabValue, tabValue }) => {
    const [data, setData] = useState([])
    const [rowSelectionModel, setRowSelectionModel] = useState([])
    const [open, setOpen] = useState(false)
    const [alert, setAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [severity, setSeverity] = useState('')
    const [loading, setLoading] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    useEffect(() => {
        if (tabValue === 1) {
            localStorage.removeItem('searchParams')

            const newUrl = window.location.pathname
            window.history.pushState({ path: newUrl }, '', newUrl)
        }
    }, [])

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (alert) {
            const timer = setTimeout(() => {
                setAlert(false)
            }, 10000)

            return () => clearTimeout(timer)
        }
    }, [alert])

    function arrayBufferToBase64(buffer) {
        // Create a Uint8Array from the ArrayBuffer
        const uint8Array = new Uint8Array(buffer)

        // Convert the Uint8Array to a binary string
        let binaryString = ''
        for (let i = 0; i < uint8Array.length; i++) {
            binaryString += String.fromCharCode(uint8Array[i])
        }

        return binaryString
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let response
                if (TableHeads.includes('RestaurantName')) {
                    response = await axios.get(
                        `${BACKEND_URL}/getRestaurantList`
                    )
                } else if (
                    TableHeads.includes('SupplierID') &&
                    TableHeads.includes('CompanyName')
                ) {
                    response = await axios.get(`${BACKEND_URL}/getSuppliers`)
                } else if (
                    TableHeads.includes('ProductID') &&
                    TableHeads.includes('SupplierID')
                ) {
                    response = await axios.get(`${BACKEND_URL}/getProducts`, {
                        type: 'application/json',
                    })
                } else if (TableHeads.includes('ShippingMethod')) {
                    response = await axios.get(
                        `${BACKEND_URL}/getShippingMethods`
                    )
                } else {
                    response = await axios.get(`${BACKEND_URL}/getCategories`)
                }
                setData(response.data)
            } catch (error) {
                console.error('Error fetching data:', error)
            } finally {
                setLoading(false)
            }
        }

        fetchData()
    }, [TableHeads])

    const getRowId = (row) => {
        if (TableHeads.includes('RestaurantID')) {
            return row.RestaurantID
        } else if (
            TableHeads.includes('SupplierID') &&
            TableHeads.includes('CompanyName')
        ) {
            return row.SupplierID
        } else if (
            TableHeads.includes('ProductID') &&
            TableHeads.includes('SupplierID')
        ) {
            return row.ProductID
        } else if (TableHeads.includes('ShippingMethod')) {
            return row.APIKey
        } else {
            return row.categoryId
        }
    }

    const tableColumns = TableHeads.map((head) => ({
        field: head,
        headerName: head.replace(/([A-Z])/g, ' $1').trim(),
        width: 175,

        renderCell: (params) => {
            if (params.field === 'MainImage') {
                return (
                    <>
                        <Avatar
                            sx={{ width: 60, height: 60 }}
                            src={
                                `${BACKEND_URL}/images/` + params.formattedValue
                            }
                        />
                    </>
                )
            }
        },
    }))

    const getTableName = (row) => {
        if (TableHeads.includes('RestaurantID')) {
            return 'Restaurants'
        } else if (
            TableHeads.includes('SupplierID') &&
            TableHeads.includes('CompanyName')
        ) {
            return 'Suppliers'
        } else if (
            TableHeads.includes('ProductID') &&
            TableHeads.includes('SupplierID')
        ) {
            return 'Products'
        } else if (TableHeads.includes('ShippingMethod')) {
            return 'Shipping'
        } else if (TableHeads.includes('categoryId')) {
            return 'Category'
        }
    }

    const handleEdit = () => {
        if (rowSelectionModel.length === 1) {
            const selectedRow = data.find(
                (row) => getRowId(row) === rowSelectionModel[0]
            )
            const queryParams = new URLSearchParams()

            TableHeads.forEach((head) => {
                // if (head !== 'ProductImages') {
                queryParams.append(head, selectedRow[head] || '')
                // }
            })

            setTabValue(0)
            const newUrl = `?${queryParams.toString()}`

            localStorage.setItem('searchParams', queryParams.toString())

            window.history.pushState({ path: newUrl }, '', newUrl)
        }
    }

    const handleDelete = async () => {
        const firstColumn = TableHeads[0]
        const selectedIds = rowSelectionModel.map((id) => {
            const selectedRow = data.find((row) => getRowId(row) === id)
            return selectedRow[firstColumn]
        })

        try {
            setLoading(true)
            await axios.post(`${BACKEND_URL}/delete`, {
                ids: selectedIds,
                tableName: getTableName(),
                firstColumn: firstColumn,
            })
            const remainingData = data.filter(
                (row) => !selectedIds.includes(row[firstColumn])
            )
            setData(remainingData)
            setRowSelectionModel([])
            setAlert(true)
            setAlertMessage('Successfully Deleted!')
            setSeverity('success')
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setAlert(true)
            setAlertMessage('Failed To Delete')
            setSeverity('error')
            console.error('Error deleting rows:', error)
        }

        setOpen(false)
    }

    return (
        <>
            {alert && (
                <CustomAlert
                    message={alertMessage}
                    severity={severity}
                    setAlert={setAlert}
                    style={{ zIndex: 99999 }}
                />
            )}
            <Grid
                item
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '-41px',
                    marginBottom: '30px',
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    className="submit-btn"
                    style={{ marginRight: '10px', backgroundColor: '#7056b5' }}
                    disabled={
                        rowSelectionModel.length > 1 ||
                        rowSelectionModel.length === 0
                    }
                    onClick={handleEdit}
                >
                    Edit
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    // className="submit-btn"
                    disabled={rowSelectionModel.length === 0}
                    onClick={handleClickOpen}
                    startIcon={<DeleteIcon />}
                >
                    Delete
                </Button>
            </Grid>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure you want to delete?
                </DialogTitle>

                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <LoadingButton
                        autoFocus
                        loadingPosition="start"
                        startIcon={<DeleteIcon />}
                        variant="outlined"
                        onClick={handleDelete}
                    >
                        Yes
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Box sx={{ height: 800, mt: 1 }}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    data && (
                        <DataGrid
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        MultiImages: false,
                                    },
                                },
                            }}
                            rows={data}
                            columns={tableColumns}
                            getRowId={getRowId}
                            checkboxSelection
                            onRowSelectionModelChange={(
                                newRowSelectionModel
                            ) => {
                                setRowSelectionModel(newRowSelectionModel)
                            }}
                            rowHeight={75}
                            pageSize={10}
                            rowSelectionModel={rowSelectionModel}
                        />
                    )
                )}
            </Box>
        </>
    )
}

CustomTable.propTypes = {
    TableHeads: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default CustomTable
